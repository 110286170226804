.acces-rapide a:hover{
  text-decoration: underline;
    box-decoration-break: clone;
  }

  .acces-rapide {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-top: 5px;
    text-align: justify;
  text-justify: inter-word;
  }

  .ministere {
    padding-left: 400px;
    text-align: right;
  }

  .size-text {
    font-size: 12px;
  }

  .title-acces{
    border-bottom:2px solid white;
    padding-top: 20px;
  }