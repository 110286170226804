.news-slider .slick-slide {
    padding: 0 5px;
  }
  
  .news-slider img {
    height: 200px;
    border-radius: 5px;
  }
  
  .news-slider .slick-list {
    margin: 0 2em;
  }
