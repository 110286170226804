.weblink .slick-slide {
    padding: 0 5px;
  }
  
  .weblink .slick-list {
    margin: 0 2em;
  }
  .arrowPositionRight{
    position: absolute;
    top: 0;
    right: -26px;
  }
  
  .arrowPositionLeft{
    /* position: absolute; */
    left: -15px;
  }
  .survol {
    color: #ffc600;
}
  