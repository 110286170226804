/* CSS Index
-----------------------------------
1. Theme default
2. HEADER
3. SLIDER
4. CATEGORY
5. ABOUT
6. APPLY
7. COURSE
8. VIDEO FEATURES
9. TEACHERS
10. PUBLICATION
11. TEASTIMONIAL
12. NEWS
13. FOOTER
14. CATEGORY 2
15. COURSE 2
16. COUNTER
17. TEACHERS 2
18. EVENT
18.1. EVENT 2
19. COUNT DOWN
20. PAGE BANNER
21. ABOUT PAGE
22. COURSES PAGE
23. COURSE SINGEl PAGE
24. EVENT PAGE
25. EVENT SINGEL PAGE
26. TEACHERS SInGEL PAGE
27. BLOG PAGE
28. BLOG SINGEL PAGE
29. SHOP PAGE
30. SHOP SINGEL PAGE
31. CONTACT PAGE
32. ACCORDION
33. PRIVACY POLICY
34. LOGIN/REGISTER

*/

/*===========================
    1. Theme default css
===========================*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Roboto:300,400,500,700");

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
  color: #ffc600;
}

a {
  color: #586470;
}
i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #1d2025;
  margin: 0px;
}

h1 {
  font-weight: 500;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #505050;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/*===== All Button Style =====*/

.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #4aa340;
  padding: 0 35px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 50px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 5;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  background-color: #4aa340;
}

.main-btn:hover {
  color: #fff;
  border-color: #11440b;
  background-color: #11440b;
}

.main-btn-2 {
  color: #4aa340;
  border-color: #586470;
  background-color: #586470;
}

.main-btn-2:hover {
  color: #586470;
  background-color: #4aa340;
  border-color: #4aa340;
}

/*===== All Section Title Style =====*/

.section-title {
}

.section-title h5 {
  color: #586470;
  position: relative;
  padding-bottom: 12px;
  font-size: 35px;
}

.section-title h5::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 2px;
  background-color: #ffc600;
  bottom: 0;
  left: 0;
}

.section-title h2 {
  font-size: 48px;
  color: #000;
  padding-top: 10px;
}

/*===== Preloader Style =====*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
}

.preloader .color-1 {
  background-color: #ffc600 !important;
}

.rubix-cube {
  border: 1px solid #fff;
  width: 48px;
  height: 48px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 4px 1px 3px 2px;
  border-radius: 50%;
}
.rotate-star {
  animation: starrotation 5s infinite linear;
}
@keyframes starrotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rubix-cube .layer {
  width: 14px;
  height: 14px;
  background-color: rgb(0, 68, 148);
  border: 1px solid #fff;
  position: absolute;
}

.rubix-cube .layer-1 {
  left: 0px;
  top: 0px;
  -webkit-animation: rubixcube4 2s infinite linear;
  animation: rubixcube4 2s infinite linear;
}

.rubix-cube .layer-2 {
  left: 16px;
  top: 0px;
  -webkit-animation: rubixcube3 2s infinite linear;
  animation: rubixcube3 2s infinite linear;
}

.rubix-cube .layer-3 {
  left: 32px;
  top: 0px;
}

.rubix-cube .layer-4 {
  left: 0px;
  top: 16px;
  -webkit-animation: rubixcube5 2s infinite linear;
  animation: rubixcube5 2s infinite linear;
}

.rubix-cube .layer-5 {
  left: 16px;
  top: 16px;
  -webkit-animation: rubixcube2 2s infinite linear;
  animation: rubixcube2 2s infinite linear;
}

.rubix-cube .layer-6 {
  left: 32px;
  top: 16px;
  -webkit-animation: rubixcube1 2s infinite linear;
  animation: rubixcube1 2s infinite linear;
}

.rubix-cube .layer-7 {
  left: 0px;
  top: 32px;
  -webkit-animation: rubixcube6 2s infinite linear;
  animation: rubixcube6 2s infinite linear;
}

.rubix-cube .layer-8 {
  left: 16px;
  top: 32px;
  -webkit-animation: rubixcube7 2s infinite linear;
  animation: rubixcube7 2s infinite linear;
}

@-webkit-keyframes rubixcube1 {
  20% {
    top: 16px;
    left: 32px;
  }
  30% {
    top: 32px;
    left: 32px;
  }
  40% {
    top: 32px;
    left: 32px;
  }
  50% {
    top: 32px;
    left: 32px;
  }
  60% {
    top: 32px;
    left: 32px;
  }
  70% {
    top: 32px;
    left: 32px;
  }
  80% {
    top: 32px;
    left: 32px;
  }
  90% {
    top: 32px;
    left: 32px;
  }
  100% {
    top: 32px;
    left: 16px;
  }
}

@keyframes rubixcube1 {
  20% {
    top: 16px;
    left: 32px;
  }
  30% {
    top: 32px;
    left: 32px;
  }
  40% {
    top: 32px;
    left: 32px;
  }
  50% {
    top: 32px;
    left: 32px;
  }
  60% {
    top: 32px;
    left: 32px;
  }
  70% {
    top: 32px;
    left: 32px;
  }
  80% {
    top: 32px;
    left: 32px;
  }
  90% {
    top: 32px;
    left: 32px;
  }
  100% {
    top: 32px;
    left: 16px;
  }
}

@-webkit-keyframes rubixcube2 {
  30% {
    left: 16px;
  }
  40% {
    left: 32px;
  }
  50% {
    left: 32px;
  }
  60% {
    left: 32px;
  }
  70% {
    left: 32px;
  }
  80% {
    left: 32px;
  }
  90% {
    left: 32px;
  }
  100% {
    left: 32px;
  }
}

@keyframes rubixcube2 {
  30% {
    left: 16px;
  }
  40% {
    left: 32px;
  }
  50% {
    left: 32px;
  }
  60% {
    left: 32px;
  }
  70% {
    left: 32px;
  }
  80% {
    left: 32px;
  }
  90% {
    left: 32px;
  }
  100% {
    left: 32px;
  }
}

@-webkit-keyframes rubixcube3 {
  30% {
    top: 0px;
  }
  40% {
    top: 0px;
  }
  50% {
    top: 16px;
  }
  60% {
    top: 16px;
  }
  70% {
    top: 16px;
  }
  80% {
    top: 16px;
  }
  90% {
    top: 16px;
  }
  100% {
    top: 16px;
  }
}

@keyframes rubixcube3 {
  30% {
    top: 0px;
  }
  40% {
    top: 0px;
  }
  50% {
    top: 16px;
  }
  60% {
    top: 16px;
  }
  70% {
    top: 16px;
  }
  80% {
    top: 16px;
  }
  90% {
    top: 16px;
  }
  100% {
    top: 16px;
  }
}

@-webkit-keyframes rubixcube4 {
  50% {
    left: 0px;
  }
  60% {
    left: 16px;
  }
  70% {
    left: 16px;
  }
  80% {
    left: 16px;
  }
  90% {
    left: 16px;
  }
  100% {
    left: 16px;
  }
}

@keyframes rubixcube4 {
  50% {
    left: 0px;
  }
  60% {
    left: 16px;
  }
  70% {
    left: 16px;
  }
  80% {
    left: 16px;
  }
  90% {
    left: 16px;
  }
  100% {
    left: 16px;
  }
}

@-webkit-keyframes rubixcube5 {
  60% {
    top: 16px;
  }
  70% {
    top: 0px;
  }
  80% {
    top: 0px;
  }
  90% {
    top: 0px;
  }
  100% {
    top: 0px;
  }
}

@keyframes rubixcube5 {
  60% {
    top: 16px;
  }
  70% {
    top: 0px;
  }
  80% {
    top: 0px;
  }
  90% {
    top: 0px;
  }
  100% {
    top: 0px;
  }
}

@-webkit-keyframes rubixcube6 {
  70% {
    top: 32px;
  }
  80% {
    top: 16px;
  }
  90% {
    top: 16px;
  }
  100% {
    top: 16px;
  }
}

@keyframes rubixcube6 {
  70% {
    top: 32px;
  }
  80% {
    top: 16px;
  }
  90% {
    top: 16px;
  }
  100% {
    top: 16px;
  }
}

@-webkit-keyframes rubixcube7 {
  80% {
    left: 16px;
  }
  90% {
    left: 0px;
  }
  100% {
    left: 0px;
  }
}

@keyframes rubixcube7 {
  80% {
    left: 16px;
  }
  90% {
    left: 0px;
  }
  100% {
    left: 0px;
  }
}

/*=====================================================
    2. HEADER css
======================================================*/

/*===== Header top =====*/

.header-top {
  background-color: #586470;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-contact ul li {
  display: inline-block;
  margin-right: 20px;
  color: #c6c6c6;
  font-size: 16px;
  font-weight: 500;
}

.header-contact ul li:last-child {
  margin-right: 0;
}

.header-contact ul li i {
  margin-right: 10px;
}

.header-contact ul li a {
  color: #c6c6c6;
  font-weight: 500;
  font-size: 16px;
}

.header-right {
}

.header-right .social {
}

.header-right .social .follow-us {
  font-size: 16px;
  color: #c6c6c6;
  padding-right: 10px;
  font-weight: 500;
}

.header-right .social ul {
}

.header-right .social ul li {
  display: inline-block;
  padding: 0 5px;
}

.header-right .social ul li a {
  color: #c6c6c6;
  font-size: 16px;
}

.header-right .social ul li a:hover {
  color: #ffc600;
}

.login-register {
}

.login-register ul {
}

.login-register ul li {
  display: inline-block;
  margin-left: 25px;
  position: relative;
}

.login-register ul li::before {
  position: absolute;
  content: "/";
  color: #ffc600;
  font-size: 16px;
  top: 0;
  left: -16px;
}

.login-register ul li:first-child::before {
  display: none;
}

.login-register ul li a {
  color: #ffc600;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s linear;
}

.login-register ul li a:hover {
  color: #fff;
}

/*===== Header Menu =====*/

.navigation {
}

.navigation.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
  border-bottom: 1px solid #cecece;
  -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  -webkit-animation: sticky 2s;
  animation: sticky 2s;
}

@-webkit-keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.navigation .navbar {
  padding: 0;
}

.navigation .navbar .navbar-nav {
  margin-right: 20px;
}

.navigation .navbar .navbar-nav li {
  position: relative;
}

.navigation .navbar .navbar-nav li a {
  font-size: 13px;
  font-weight: 500;
  color: #586470;
  text-transform: uppercase;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  padding: 32px 15px 32px 15px;
  display: block;
}

.navigation .navbar .navbar-nav > li > a.active,
.navigation .navbar .navbar-nav > li:hover > a {
  color: #ffffff;
  background-color: #4aa340;
}

.navigation .navbar .navbar-nav li .sub-menu {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #4aa340;
  width: 350px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: 99;
}

.navigation .navbar .navbar-nav li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navigation .navbar .navbar-nav li .sub-menu li {
  margin-right: 0;
}

.navigation .navbar .navbar-nav li .sub-menu li a {
  padding: 10px 10px;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  text-align: left;
}

.navigation .navbar .navbar-nav li .sub-menu li a.active,
.navigation .navbar .navbar-nav li .sub-menu li a:hover {
  padding-left: 20px;
  color: #ffffff;
  background-color: #11440b;
}

.sub-nav-toggler {
  display: none;
}

.navigation .navbar .navbar-nav li .sub-menu li:last-child a {
  border-bottom: 0;
}

.navbar .navbar-toggler {
  padding: 3px 8px;
  margin: 18px 0;
  -webkit-transform: all 0.4s linear;
  transform: all 0.4s linear;
}

.navbar .navbar-toggler .icon-bar {
  width: 30px;
  height: 2px;
  background-color: #586470;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.navbar .navbar-toggler.active .icon-bar:nth-of-type(1) {
  -webkit-transform: rotate(46deg);
  transform: rotate(46deg);
  top: 7px;
}

.navbar .navbar-toggler.active .icon-bar:nth-of-type(2) {
  opacity: 0;
}

.navbar .navbar-toggler.active .icon-bar:nth-of-type(3) {
  -webkit-transform: rotate(134deg);
  transform: rotate(134deg);
  top: -7px;
}

.right-icon {
}

.right-icon ul li {
  display: inline-block;
  margin-right: 17px;
  padding: 22px 0;
}

.right-icon ul li:last-child {
  margin-right: 0;
}

.right-icon ul li a {
  font-size: 18px;
  color: #586470;
  position: relative;
  padding-right: 7px;
}

.right-icon ul li a span {
  font-size: 12px;
  color: #fff;
  background-color: #ffc600;
  border-radius: 50%;
  padding: 0px 4px;
  position: absolute;
  top: -5px;
  right: 0;
}

/*===== HEADER TOW =====*/

.header-two {
}

.header-two .header-top {
  background-color: #fff;
  border-bottom: 1px solid #edf0f2;
}

.header-two .header-contact ul li {
  color: #586470;
}

.header-two .header-contact ul li a {
  color: #586470;
}

.header-two .header-right .social .follow-us {
  color: #586470;
}

.header-two .header-right .social ul li a {
  color: #586470;
}

.header-two .header-right .social ul li a:hover {
  color: #ffc600;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.header-two .login-register ul li a {
  color: #586470;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 18px;
  background: #ffc600;
  border-radius: 5px;
  transition: all 0.3s linear;
}

.header-two .login-register ul li a:hover {
  background: #586470;
  color: #fff;
}

.header-two .login-register ul li::before {
  display: none;
}

.header-two .login-register ul li {
  margin-left: 10px;
}

.header-two .header-right .social {
  margin-top: 5px;
}

.header-two .navigation .navbar .navbar-nav li .sub-menu {
  background-color: #fff;
}

.header-two .navigation .navbar .navbar-nav li .sub-menu li a {
  border-bottom: 1px solid #edeff2;
  color: #586470;
}

.header-two .navigation .navbar .navbar-nav li .sub-menu li a.active,
.header-two .navigation .navbar .navbar-nav li .sub-menu li a:hover {
  color: #ffc600;
}

/*===== HEADER THREEE =====*/

.header-three {
}

.header-three .navigation {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 10px 0;
}

.header-three .navigation .navbar .navbar-nav li a {
  color: #fff;
}

.header-three .navigation .navbar .navbar-nav li a:hover {
  color: #ffc600;
}

.header-three .navigation .right-icon ul li a {
  color: #fff;
}

.header-three .navigation.sticky {
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 0 0;
}

.header-three .navigation.sticky .navbar .navbar-nav li a {
  color: #586470;
}

.header-three .navigation.sticky .navbar .navbar-nav li a:hover {
  color: #ffc600;
}

.header-three .navigation.sticky .right-icon ul li a {
  color: #586470;
}

.header-three .navigation .navbar .navbar-nav li .sub-menu {
  background-color: #fff;
}

.header-three .navigation .navbar .navbar-nav li .sub-menu li a {
  border-bottom: 1px solid #edeff2;
  color: #586470;
}

.header-three .navigation .navbar .navbar-nav li .sub-menu li a:hover {
  color: #ffc600;
}

/*===== HEADER FORE =====*/

.header-four .navigation .navbar .navbar-nav li .sub-menu {
  background-color: #fff;
}

.header-four .navigation .navbar .navbar-nav li .sub-menu li a {
  border-bottom: 1px solid #edeff2;
  color: #586470;
}

.header-four .navigation .navbar .navbar-nav li .sub-menu li a.active,
.header-four .navigation .navbar .navbar-nav li .sub-menu li a:hover {
  color: #ffc600;
}

/*===== SEARCH BOX =====*/

.search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 41, 77, 0.5);
  z-index: 999;
  display: none;
}

.closebtn {
  position: absolute;
  top: 20px;
  right: 50px;
  cursor: pointer;
  width: 30px;
  height: 25px;
}

.closebtn span {
  width: 30px;
  height: 2px;
  background-color: #fff;
  position: relative;
  display: block;
}

.closebtn span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 11px;
}

.closebtn span:nth-of-type(2) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 9px;
}

.search-box .search-form input {
  width: 50%;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #586470;
  color: #586470;
  padding-left: 20px;
  border-radius: 50px;
}

.search-box .search-form button {
  position: absolute;
  top: 50%;
  left: 72%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #586470;
  cursor: pointer;
  font-size: 16px;
}

/*=====================================================
    3. SLIDER css
======================================================*/


.single-slider {
  /* padding-bottom: 300px; */
  height: 500px;
  width: 100%;
}

.slider-cont {
  position: relative;
  top: 100%;
  z-index: 5;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 50px;
}

.slider-cont h4 {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #fff;
  padding-bottom: 15px;
}

.slider-cont h1 {
  font-size: 60px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #fff;
  padding-bottom: 25px;
}

.slider-cont p {
  font-size: 18px;
  color: #fff;
  font-weight: 600;


  padding-bottom: 10px;
}

.slider-cont ul {
}

.slider-cont ul li {
  display: inline-block;
  margin-right: 15px;
}

.slider-cont ul li:last-child {
  margin-right: 0;
}

.slider-active {
}

.slider-active .slick-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s linear;
}

.slider-active .next.slick-arrow {
  left: auto;
  right: 0;
}

.slider-active:hover .slick-arrow {
  left: 30px;
  opacity: 1;
  visibility: visible;
}

.slider-active:hover .next.slick-arrow {
  left: auto;
  right: 30px;
}

.slider-active .slick-arrow i {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 48px;
  border-radius: 50%;
  border: 1px solid #fff;
  text-align: center;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.slider-active .slick-arrow i:hover {
  background-color: #ffc600;
  border-color: #ffc600;
}

/*===== Index-2 =====*/

.slider-2 .slider-cont h1 {
  padding-bottom: 40px;
}

.slider-2.single-slider {
  padding-top: 230px;
  padding-bottom: 250px;
}

/*===== Index-3 =====*/

#slider-part-3 {
  position: relative;
  padding-top: 220px;
  padding-bottom: 120px;
}

#slider-part-3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.slider-cont-3 {
  position: relative;
  z-index: 5;
  background-color: rgba(7, 41, 77, 0.8);
  padding: 70px 100px 80px;
}

.slider-cont-3 h2 {
  color: #fff;
  font-size: 48px;
  padding-bottom: 8px;
}

.slider-cont-3 span {
  font-size: 24px;
  color: #fff;
}

.slider-search {
  background-color: #fff;
  border-radius: 5px;
}

.slider-search .nice-select {
  width: 100%;
  height: 55px;
  line-height: 55px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
}

.slider-search .nice-select span {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #586470;
  font-weight: 400;
}

.slider-search .nice-select .list {
  width: 100%;
  border-radius: 0;
  margin: 0;
}

.slider-search .nice-select::after {
  border-bottom: 0;
  border-right: 8px solid #586470;
  border-top: 8px solid transparent;
  height: 0px;
  margin-top: -4px;
  width: 0px;
}

.slider-search input {
  width: 100%;
  height: 55px;
  border: 0;
  border-left: 1px solid #a8a8a8;
  padding-left: 25px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #8a8a8a;
}

.slider-search .main-btn {
  line-height: 53px;
  width: 100%;
  padding: 0;
}

.slider-search .main-btn:hover {
  border-color: #586470;
}

.slider-feature {
}

.single-slider-feature {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.single-slider-feature .icon {
}

.single-slider-feature .cont {
  padding-left: 30px;
}

.single-slider-feature .cont h3 {
  color: #fff;
  padding-bottom: 3px;
  margin-top: -3px;
  font-size: 24px;
}

.single-slider-feature .cont span {
  color: #fff;
  font-size: 15px;
}

/*===== Index-4 =====*/

.slider-4 {
  position: relative;
}

.slider-4::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-image: url(../images/slider/slider-shape.png);
  background-position: center center;
  width: 100%;
  height: 100%;
}

.slider-cont-4 h1 {
  color: #586470;
  padding-bottom: 35px;
}

.slider-cont-4 p {
  color: #586470;
}



/*=====================================================
    4. CATEGORY css
======================================================*/

.color-1 {
  background-color: #4aa340;
}

.color-2 {
  background-color: #ffc600;
}

.color-3 {
  background-color: rgb(0, 68, 148);
  /* background-color: #b5d56a; */
}

.category {
  background-color: #e9eaea;
  border-radius: 10px;
  margin-top: -150px;
  position: relative;
  z-index: 5;
}

.category-text {
}

.category-text h2 {
  font-size: 36px;
  color: #606b77;
  padding: 0 50px;
}

.single-category {
  padding: 20px 0;
  border-radius: 5px;
  display: block;
}

.single-category .icon {
  display: inline-block;
}

.single-category .cont {
  display: block;
}

.single-category .cont span {
  color: #fff;
  font-size: 18px;
  padding-top: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.category-slide {
}

.category-slide a {
  display: block;
}

.category-slide .slick-arrow {
  position: absolute;
  top: 50%;
  right: -50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.category-slide .prev.slick-arrow {
  right: auto;
  left: -50px;
}

.category-slide .slick-arrow i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  background-color: #557495;
  text-align: center;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.category-slide .slick-arrow i:hover {
  background-color: #ffc600;
}

/*===== Index-4 =====*/

.category-tow {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

.category-text-tow h2 {
  color: #586470;
}

/*=====================================================
    5. ABOUT css
======================================================*/

#about-part {
  position: relative;
  padding-bottom: 20px;
}

.about-bg {
  width: 52%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -2;
}

.about-bg::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 1))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.about-bg img {
  width: 100%;
}

.about-cont p {
  padding-top: 30px;
}

.about-event {
  -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
  padding: 65px 30px 37px;
  background-color: rgba(255, 255, 255, 0.95);
}

.about-event .event-title h3 {
  font-size: 35px;
  color: #586470;
  padding-bottom: 5px;
}

.about-event ul li .single-event {
  padding: 10px 0;
  border-bottom: 1px solid #cecece;
}

.about-event ul li:last-child .single-event {
  border-bottom: 0;
}

.about-event ul li .single-event span {
  font-size: 14px;
  color: #8a8a8a;
  margin-right: 20px;
}

.about-event ul li .single-event span i {
  color: #ffc600;
  margin-right: 3px;
}

.about-event ul li .single-event a {
  display: block;
}

.about-event ul li .single-event a h4 {
  padding-top: 5px;
  padding-bottom: 10px;
  color: #4aa340;
  font-size: 20px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.about-event ul li .single-event a:hover h4 {
  color: #ffc600;
}
.single-event a h3 {
  color: #4aa340;
}

.single-event a:hover h3 {
  color: #ffc600;
}
/*===== Index-4 =====*/

.about-tow {
  position: relative;
  padding-bottom: 150px !important;
}

.about-shape {
  position: absolute;
  top: 0;
  height: 100%;
  width: 30%;
}

.about-shape img {
  width: 100%;
}

.about-image-tow {
  position: relative;
}

.about-image-tow::before {
  position: absolute;
  content: "";
  background-image: url(../images/about/dot-shape.png);
  background-position: center center;
  background-size: contain;
  width: 305px;
  height: 370px;
  left: -100px;
  bottom: -60px;
  z-index: -1;
}

.about-image-tow img {
  width: 100%;
  border-radius: 5px;
}

.about-shape-tow {
  position: absolute;
  left: -36px;
  bottom: 170px;
}

.about-shape-three {
  position: absolute;
  bottom: -30px;
  left: 85px;
}

/*=====================================================
    6. APPLY css
======================================================*/

.apply {
  margin-top: -100px;
  position: relative;
}

.apply-color-1 {
  background-color: #586470;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.apply-color-2 {
  background-color: #092e56;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.apply .apply-cont {
  padding: 45px 50px 50px;
}

.apply .apply-cont h3 {
  font-size: 30px;
  color: #fff;
  padding-bottom: 15px;
}

.apply .apply-cont p {
  color: #fff;
  padding-bottom: 45px;
}

.apply .apply-cont .main-btn:hover {
  border-color: #ffc600;
}

/*===== Index-4 =====*/

.apply-area-tow {
  position: relative;
}

.apply-area-tow::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/apply-tow/apply-shape.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.apply-bg-one,
.apply-bg-tow {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #586470;
  z-index: -1;
}

.apply-bg-tow {
  background-color: #103965;
  left: auto;
  right: 0;
}

.single-apply {
}

.single-apply .apply-content {
}

.single-apply .apply-content .apply-title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  padding-bottom: 20px;
}

.single-apply .apply-content p {
  color: #fff;
  padding-bottom: 35px;
}

.single-apply .apply-content .main-btn:hover {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}

/*=====================================================
    7. COURSE css
======================================================*/

.single-course {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.single-course > .thum {
  position: relative;
}

.single-course .thum .image {
  overflow: hidden;
}

.single-course .thum .image img {
  width: 100%;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course:hover .thum .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-course .thum .price {
  position: absolute;
  right: 60px;
  bottom: -24px;
}

.single-course .thum .price span {
  font-size: 14px;
  color: #586470;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  background-color: #ffc600;
  text-align: center;
}

.single-course .cont {
  padding-top: 28px;
  padding-bottom: 34px;
  padding-left: 30px;
  padding-right: 30px;
}

.single-course .cont > ul {
  display: inline-block;
  margin-right: 10px;
}

.single-course .cont ul li {
  display: inline-block;
  margin: 1px;
}

.single-course .cont ul li > i {
  font-size: 13px;
  color: #ffc600;
}

.single-course .cont span {
  font-size: 13px;
  color: #000;
}

.single-course .cont h4 {
  font-weight: 600;
  font-size: 24px;
  color: #000;
  padding-top: 15px;
  padding-bottom: 40px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course .cont h4:hover {
  color: #ffc600;
}

.single-course .course-teacher {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  overflow: hidden;
}

.single-course .course-teacher > .thum {
  display: inline-block;
}

.single-course .course-teacher > .thum img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.single-course .course-teacher .name {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  bottom: 13px;
}

.single-course .course-teacher .name a h6 {
  font-size: 15px;
  color: #24486e;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course .course-teacher .name a:hover h6 {
  color: #ffc600;
}

.single-course .course-teacher .admin {
  display: inline-block;
  float: right;
  overflow: hidden;
}

.single-course .course-teacher .admin ul {
  margin-top: 8px;
}

.single-course .course-teacher .admin ul li {
  margin-right: 20px;
}

.single-course .course-teacher .admin ul li:last-child {
  margin-right: 0;
}

.single-course .course-teacher .admin ul li a {
  font-size: 15px;
  color: #8a8a8a;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course .course-teacher .admin ul li a:hover {
  color: #ffc600;
}

.single-course .course-teacher .admin ul li a span {
  color: #8a8a8a;
  margin-left: 3px;
}

.course-slide {
}

.course-slide .slick-arrow {
  position: absolute;
  top: -125px;
  right: 0;
}

.course-slide .prev.slick-arrow {
  left: auto;
  right: 50px;
}

.course-slide .slick-arrow i {
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-radius: 5px;
  color: #000;
  border: 2px solid #aaaaaa;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.course-slide .slick-arrow:hover i {
  border-color: #ffc600;
  background-color: #ffc600;
}

/*=====================================================
    8. VIDEO FEATURES css
======================================================*/

#video-feature {
  position: relative;
}

.feature-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(7, 41, 77, 0.9);
}

.video {
  padding-left: 80px;
}

.video i {
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  font-size: 24px;
  background-color: #ffc600;
  color: #586470;
  border-radius: 50%;
}

.feature {
  position: relative;
  z-index: 5;
}

.feature .feature-title {
}

.feature .feature-title h3 {
  font-size: 36px;
  color: #fff;
  padding-bottom: 15px;
}

.feature ul li .single-feature {
  padding-top: 55px;
  overflow: hidden;
}

.feature ul li .single-feature .icon {
  float: left;
  overflow: hidden;
  display: inline-block;
  padding-right: 30px;
}

.feature ul li .single-feature .cont {
  width: 78%;
  float: left;
  overflow: hidden;
}

.feature ul li .single-feature .cont h4 {
  font-size: 24px;
  color: #fff;
  padding-bottom: 15px;
}

.feature ul li .single-feature .cont p {
  color: #fff;
}

/*===== Index-4 =====*/

.video-feature-tow {
}

.video-tow {
}

.video-tow .video-image {
  position: relative;
}

.video-tow .video-image img {
  width: 100%;
  border-radius: 5px;
}

.video-tow .video-image a {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.feature-tow {
}

.feature-tow .feature-title h3 {
  color: #103965;
}

.feature-tow .single-feature .cont {
}

.feature-tow ul li .single-feature .cont h4 {
  color: #103965;
}

.feature-tow ul li .single-feature .cont p {
  color: #505050;
}

/*=====================================================
    9. TEACHERS css
======================================================*/

.teachers-cont {
}

.teachers-cont p {
  padding-top: 30px;
}

.single-teachers {
  position: relative;
}

.single-teachers .image {
  border-radius: 5px;
  overflow: hidden;
}

.single-teachers .image img {
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  width: 100%;
}

.single-teachers:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-teachers .cont {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  width: 80%;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 auto;
  padding: 15px;
}

.single-teachers .cont a {
  display: block;
}

.single-teachers .cont a h6 {
  color: #000;
  font-size: 15px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-teachers .cont a:hover h6 {
  color: #ffc600;
}

.single-teachers .cont span {
  font-size: 14px;
  color: #8a8a8a;
}

/*===== Index-4 =====*/

.teachers-area-tow {
  position: relative;
}

.teachers-area-tow::before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/teachers/teachers-shape.png);
  background-position: center center;
  background-size: contain;
}

/*=====================================================
    10. PUBLICATION css
======================================================*/

.single-publication {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}

.single-publication .image {
  position: relative;
}

.single-publication .image img {
  width: 100%;
}

.single-publication .image .add-cart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 41, 77, 0.8);
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  text-align: center;
}

.single-publication .image:hover .add-cart {
  opacity: 1;
}

.single-publication .image .add-cart ul {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.single-publication .image .add-cart ul li {
  display: inline-block;
  margin: 0 5px;
}

.single-publication .image .add-cart ul li a {
  font-size: 16px;
  color: #ffc600;
  width: 35px;
  line-height: 35px;
  border: 1px solid #ffc600;
  text-align: center;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-publication .image .add-cart ul li a:hover {
  background-color: #ffc600;
  color: #586470;
}

.single-publication .content {
}

.single-publication .content .book-title a {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  margin-bottom: 5px;
}

.single-publication .content .book-title a:hover {
  color: #ffc600;
}

.single-publication .content .writer-name {
  font-size: 15px;
  color: #505050;
}

.single-publication .content .writer-name span {
  color: #586470;
  padding-bottom: 10px;
}

.single-publication .content .price {
}

.single-publication .content .price .normal-price {
  font-size: 16px;
  font-weight: 600;
  color: #586470;
}

.single-publication .content .price .discount-price {
  font-size: 14px;
  color: #505050;
  margin-left: 10px;
  position: relative;
}

.single-publication .content .price .discount-price::before {
  position: absolute;
  content: "";
  background-color: #505050;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-publication .content .price-btn {
  border-top: 1px solid #eee;
  padding-top: 5px;
}

.single-publication .content .price-btn .main-btn {
  padding: 0 13px;
  line-height: 40px;
  height: 42px;
  font-size: 15px;
}

.single-publication .content .price-btn .main-btn i {
  margin-right: 5px;
}

.single-publication .content .description {
  padding-top: 10px;
  padding-bottom: 25px;
}

/*=====================================================
    11. TEASTIMONIAL css
======================================================*/

#testimonial {
}

#testimonial .section-title {
  position: relative;
  z-index: 5;
}

#testimonial .section-title h5,
#testimonial .section-title h2 {
  color: #fff;
}

#testimonial .section-title h5::before {
  background-color: #fff;
}

.testimonial-slide {
  position: relative;
  z-index: 5;
}

.single-testimonial {
  position: relative;
}

.single-testimonial .testimonial-thum {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-testimonial .testimonial-thum img {
  border-radius: 5px;
}

.single-testimonial .testimonial-thum .quote {
  position: absolute;
  right: -22px;
  top: -22px;
}

.single-testimonial .testimonial-thum .quote i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background-color: #ffc600;
  border-radius: 50%;
  color: #586470;
  font-size: 18px;
}

.single-testimonial .testimonial-cont {
  padding-left: 140px;
}

.single-testimonial .testimonial-cont p {
  color: #fff;
  padding-bottom: 32px;
}

.single-testimonial .testimonial-cont h6 {
  color: #fff;
  padding-bottom: 4px;
}

.single-testimonial .testimonial-cont span {
  color: #fff;
  font-size: 18px;
}

.testimonial-slide .slick-dots {
  margin: 0 auto;
}

.testimonial-slide .slick-dots li {
  display: inline-block;
  margin-top: 15px;
}

.testimonial-slide .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 0;
  padding: 0;
  border: 0;
  margin: 0 3px;
  cursor: pointer;
}

.testimonial-slide .slick-dots li.slick-active button {
  background-color: #ffc600;
}

/*=====================================================
    12. NEWS css
======================================================*/

.single-news {
}

.single-news .news-thum {
}

.single-news .news-thum img {
  width: 100%;
  border-radius: 5px;
}

.single-news .news-cont {
}

.single-news .news-cont ul li {
  display: inline-block;
  margin-right: 25px;
}

.single-news .news-cont ul li:last-child {
  margin-right: 0;
}

.single-news .news-cont ul li a {
  color: #8a8a8a;
  font-size: 15px;
}

.single-news .news-cont ul li a i {
  color: #ffc600;
  margin-right: 8px;
}

.single-news .news-cont ul li a span {
  color: #586470;
}

.single-news .news-cont a {
  display: block;
}

.single-news .news-cont a h3 {
  font-size: 24px;
  color: #000;
  padding-bottom: 20px;
  padding-top: 10px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-news .news-cont a:hover h3 {
  color: #ffc600;
}

.news-list .news-cont h3 {
  font-size: 20px;
}

.news-list .news-cont ul li a {
  font-size: 14px;
}

/*=====================================================
    13. FOOTER css
======================================================*/

.footer-top {
  background-color: #586470;
}

.footer-about {
  padding-right: 50px;
}

.footer-about p {
  color: #fff;
  padding-top: 15px;
}

.footer-about ul li {
  display: inline-block;
  margin-right: 15px;
}

.footer-about ul li:last-child {
  margin-right: 0;
}

.footer-about ul li a {
  width: 35px;
  line-height: 35px;
  font-size: 15px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  background-color: #021d3a;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.footer-about ul li a:hover {
  background-color: #ffc600;
  color: #021d3a;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.footer-title h6 {
  font-size: 18px;
  color: #fff;
}

.footer-link {
  overflow: hidden;
}

.footer-link ul {
  width: 50%;
  float: left;
  overflow: hidden;
}

.footer-link ul li {
  line-height: 35px;
}

.footer-link ul li a {
  color: #fff;
  font-size: 15px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.footer-link ul li a i {
  margin-right: 8px;
  color: #ffc600;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.footer-link ul li a:hover {
  color: #ffc600;
}

.footer-link ul li a:hover i {
  margin-right: 15px;
}

.support ul {
  float: none;
  width: 100%;
}

.footer-address ul li {
  position: relative;
  margin-bottom: 10px;
}

.footer-address ul li:last-child {
  margin-bottom: 0;
}

.footer-address ul li .icon i {
  font-size: 18px;
  color: #ffc600;
  position: absolute;
  left: 0;
  top: 5px;
}

.footer-address ul li .cont {
  padding-left: 35px;
}

.footer-address ul li .cont p {
  color: #fff;
}

.footer-copyright {
  background-color: #021d3a;
}

.footer-copyright .copyright p {
  color: #fff;
}

.footer-copyright .copyright p span {
  font-weight: 600;
}

.back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 35px;
  background-color: #ffc600;
  color: #fff;
  font-size: 28px;
  border-radius: 5px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
  display: none;
}

.back-to-top:hover {
  color: #fff;
  background-color: #021d3a;
}

/*=====================================================
    14. CATEGORY 2 css
======================================================*/

.category-2-items {
}

.category-2-items .single-items {
  position: relative;
}

.category-2-items .single-items .items-image {
  position: relative;
}

.category-2-items .single-items .items-image img {
  width: 100%;
  border-radius: 5px;
}

.category-2-items .single-items .items-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 41, 77, 0.6);
  border-radius: 5px;
}

.category-2-items .single-items .items-cont {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.category-2-items .single-items .items-cont h5 {
  color: #fff;
  font-size: 18px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.category-2-items .single-items .items-cont span {
  font-size: 14px;
  color: #fff;
}

.category-2-items .single-items .items-cont h5:hover {
  color: #ffc600;
}

.category-form {
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 75px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 75px 0px rgba(0, 0, 0, 0.1);
  margin-top: -154px;
}

.category-form .form-title {
  background-color: #586470;
  padding-top: 35px;
  padding-bottom: 40px;
}

.category-form .form-title h3 {
  color: #ffc600;
  font-size: 36px;
}

.category-form .form-title span {
  font-size: 24px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.category-form .main-form {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.category-form .main-form .single-form {
  margin-top: 20px;
}

.category-form .main-form .single-form input {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  font-size: 15px;
  color: #8a8a8a;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
}

.category-form .main-form .single-form .main-btn {
  width: 100%;
  line-height: 60px;
  margin-top: 20px;
}

/*=====================================================
    15. COURSE 2 css
======================================================*/

.single-course-2 {
}

.single-course-2 > .thum {
  position: relative;
}

.single-course-2 > .thum .image > img {
  border-radius: 5px;
  width: 100%;
}

.single-course-2 > .thum .price {
  position: absolute;
  bottom: 40px;
  right: 15px;
  z-index: 5;
}

.single-course-2 > .thum .price span {
  font-size: 14px;
  color: #586470;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  background-color: #ffc600;
  text-align: center;
}

.single-course-2 > .thum .course-teacher {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.single-course-2 > .thum .course-teacher > .thum {
  display: inline-block;
}

.single-course-2 > .thum .course-teacher > .thum img {
  border-radius: 50%;
}

.single-course-2 > .thum .course-teacher .name {
  display: inline-block;
  margin-left: 5px;
  bottom: 13px;
}

.single-course-2 > .thum .course-teacher .name h6 {
  color: #fff;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course-2 > .thum .course-teacher .name h6:hover {
  color: #ffc600;
}

.single-course-2 > .thum .course-teacher .review {
  display: inline-block;
  margin-left: 15px;
  bottom: 13px;
}

.single-course-2 > .thum .course-teacher .review ul li {
  display: inline-block;
  font-size: 12px;
  color: #ffc600;
}

.single-course-2 > .thum .course-teacher .review ul li {
}

.single-course-2 .cont {
  padding-top: 25px;
}

.single-course-2 .cont h4 {
  font-weight: 600;
  font-weight: 24px;
  color: #000;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course-2 .cont h4:hover {
  color: #ffc600;
}

/*=====================================================
    16. COUNTER css
======================================================*/

.single-counter {
  position: relative;
  z-index: 5;
}

.single-counter span {
  font-size: 36px;
  color: #ffc600;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.single-counter p {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 15px;
}

/*===== Index-3 =====*/

.counter-3 {
}

.counter-3 p {
  color: #586470;
  font-weight: 600;
}

/*===== Index-4 =====*/

.counter-area-tow {
}

.single-counter-tow {
  position: relative;
  z-index: 5;
}

.single-counter-tow .counter-icon {
}

.single-counter-tow .counter-content {
  padding-left: 30px;
}

.single-counter-tow span {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.single-counter-tow p {
  color: #fff;
}

/*=====================================================
    17. TEACHERS 2 css
======================================================*/

.teachers-2 {
}

.teachers-2 .teachers-2-single {
  background-color: #fff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px 15px;
}

.teachers-2 .teachers-2-single .thum {
}

.teachers-2 .teachers-2-single .thum img {
  border-radius: 50%;
}

.teachers-2 .teachers-2-single .cont {
  padding-left: 20px;
}

.teachers-2 .teachers-2-single .cont a {
}

.teachers-2 .teachers-2-single .cont a h5 {
  font-size: 18px;
  color: #000;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.teachers-2 .teachers-2-single .cont a:hover h5 {
  color: #ffc600;
}

.teachers-2 .teachers-2-single .cont p {
  font-size: 15px;
}

.teachers-2 .teachers-2-single .cont span {
  font-size: 14px;
  color: #586470;
}

.teachers-2 .teachers-2-single .cont span i {
  margin-right: 5px;
  color: #ffc600;
}

.student-slide {
  z-index: 5;
}

.happy-student {
  background-color: #586470;
  padding-top: 65px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
  border-radius: 5px;
  position: relative;
}

.happy-student {
  background-color: #586470;
  padding-top: 65px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
  border-radius: 5px;
  position: relative;
}

.happy-student .happy-title {
}

.happy-student .happy-title h3 {
  font-size: 36px;
  color: #fff;
  padding-bottom: 60px;
}

.happy-student .single-student {
  position: relative;
  z-index: 5;
}

.happy-student .single-student img {
  padding-bottom: 20px;
}

.happy-student .single-student p {
  color: #fff;
  padding-bottom: 30px;
  padding-right: 135px;
}

.happy-student .single-student h6 {
  color: #fff;
  font-size: 16px;
}

.happy-student .single-student span {
  font-size: 14px;
  color: #fff;
}

.happy-student .student-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*=====================================================
    18. EVENT css
======================================================*/

.event-bg {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.event-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(7, 41, 77, 0.3)),
    color-stop(10%, rgba(7, 41, 77, 0.3)),
    to(rgba(7, 41, 77, 1))
  );
  background: linear-gradient(
    to right,
    rgba(7, 41, 77, 0.3) 0%,
    rgba(7, 41, 77, 0.3) 10%,
    rgba(7, 41, 77, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d586470', endColorstr='#586470', GradientType=1);
}

.event-2 .event-title h3 {
  font-size: 36px;
  color: #fff;
  padding-bottom: 5px;
}

.event-2 ul li .single-event {
  padding: 25px 0;
  border-bottom: 1px solid #cecece;
}

.event-2 ul li:last-child .single-event {
  border-bottom: 0;
}

.event-2 ul li .single-event span {
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
}

.event-2 ul li .single-event span i {
  color: #ffc600;
  margin-right: 3px;
}

.event-2 ul li .single-event a {
  display: block;
}

.event-2 ul li .single-event a h4 {
  padding-top: 5px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 24px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.event-2 ul li .single-event a:hover h4 {
  color: #ffc600;
}

/*=====================================================
    18.1 EVENT 2 css
======================================================*/

.single-event-list-2 {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}

.single-event-list-2 .event-thum {
}

.single-event-list-2 .event-thum img {
  width: 100%;
  border-radius: 5px;
}

.single-event-list-2 .event-cont {
}

.single-event-list-2 .event-cont span {
  font-size: 14px;
  color: #8a8a8a;
  margin-right: 20px;
}

.single-event-list-2 .event-cont span i {
  color: #ffc600;
  margin-right: 3px;
}

.single-event-list-2 .event-cont a {
  display: block;
}

.single-event-list-2 .event-cont a h4 {
  padding-top: 20px;
  padding-bottom: 10px;
  color: #000;
  font-size: 20px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-event-list-2 .event-cont a:hover h4 {
  color: #ffc600;
}

/*=====================================================
    19. COUNT DOWN css
======================================================*/

.count-down-cont {
  position: relative;
  z-index: 5;
}

.count-down-cont h3 {
  font-size: 30px;
  color: #fff;
  font-weight: 500;
  padding-bottom: 5px;
}

.count-down-cont h2 {
  font-size: 72px;
  color: #ffc600;
  font-weight: 700;
  padding-bottom: 37px;
}

.count-down-time {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.count-down-time .single-count {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 25%;
}

.count-down-time .single-count .number {
  font-size: 72px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: block;
}

.count-down-time .single-count .title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  display: block;
}

.category-form-3 {
  margin-top: 0;
  position: relative;
  z-index: 5;
}

.category-form-3 .form-title {
  background-color: #ffc600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.category-form-3 .form-title h3 {
  color: #586470;
}

.category-form-3 .form-title span {
  font-weight: 600;
}

.category-form-3 .main-btn {
  color: #fff;
}

.category-form-3 .main-btn:hover {
  color: #ffc600;
  border-color: #586470;
}

/*=====================================================
    20. PAGE BANNER  css
======================================================*/

.page-banner-cont {
  position: relative;
  z-index: 5;
}

.page-banner-cont h2 {
  font-size: 52px;
  color: #fff;
  padding-bottom: 15px;
}

.page-banner-cont .breadcrumb {
  margin: 0;
  background: none;
  padding: 0;
}

.page-banner-cont .breadcrumb .breadcrumb-item {
  color: #ffc600;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}

.page-banner-cont .breadcrumb .breadcrumb-item a {
  color: #fff;
}

.page-banner-cont .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  content: "/";
  font-size: 18px;
}

/*=====================================================
    21. ABOUT PAGE css
======================================================*/

.about-image img {
  width: 100%;
  border-radius: 5px;
}

.about-single-items {
}

.about-single-items span {
  font-size: 60px;
  color: #dbdbdb;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 46px;
  padding-bottom: 22px;
}

.about-single-items h4 {
  color: #000;
  font-size: 24px;
  padding-bottom: 25px;
}

/*=====================================================
    22. COURSES PAGE css
======================================================*/

.tab-content {
}

.tab-content .single-course .course-teacher .name {
  bottom: 0;
}

.courses-top-search {
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  overflow: hidden;
}

.courses-top-search .nav {
  margin-top: 5px;
}

.courses-top-search .nav .nav-item {
  margin-right: 15px;
  font-size: 15px;
  color: #8a8a8a;
}

.courses-top-search .nav .nav-item a {
  font-size: 16px;
  color: #8a8a8a;
}

.courses-top-search .nav .nav-item a.active {
  color: #ffc600;
}

.courses-search {
  position: relative;
}

.courses-search input {
  height: 30px;
  width: 240px;
  background-color: #f6f6f6;
  border: 0;
  color: #8a8a8a;
  font-size: 14px;
  border-radius: 5px;
  padding: 0 20px;
}

.courses-search button {
  position: absolute;
  top: 0;
  right: 15px;
  padding: 0;
  height: 30px;
  font-size: 15px;
  color: #8a8a8a;
  border: 0;
  background: none;
  cursor: pointer;
}

#courses-list .single-course .thum .price {
  right: -25px;
  bottom: auto;
  top: 30px;
}

.courses-pagination {
}

.courses-pagination .pagination {
}

.courses-pagination .pagination .page-item {
  margin: 0 5px;
}

.courses-pagination .pagination .page-item a {
  font-size: 15px;
  color: #586470;
  width: 40px;
  height: 40px;
  line-height: 36px;
  border: 2px solid #aaa;
  border-radius: 5px;
  text-align: center;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.courses-pagination .pagination .page-item a.active,
.courses-pagination .pagination .page-item a:hover {
  background-color: #ffc600;
  border-color: #ffc600;
}

/*=====================================================
    23. COURSE SINGEl PAGE css
======================================================*/

.courses-single-left {
  padding: 45px 50px;
  background-color: #fff;
}

.courses-single-left .title {
}

.courses-single-left .title h3 {
  font-size: 30px;
  color: #000;
  font-weight: 600;
  padding-bottom: 25px;
}

.courses-single-left .course-terms {
}

.courses-single-left .course-terms > ul > li {
  display: inline-block;
  margin-right: 60px;
}

.courses-single-left .course-terms > ul li:last-child {
  margin-right: 0;
}

.courses-single-left .course-terms ul li .teacher-name {
  position: relative;
}

.courses-single-left .course-terms ul li .teacher-name .thum {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

.courses-single-left .course-terms ul li .teacher-name .thum img {
  border-radius: 50%;
}

.courses-single-left .course-terms ul li .teacher-name .name {
  padding-left: 60px;
}

.courses-single-left .course-terms ul li .review span,
.courses-single-left .course-terms ul li .teacher-name .name span,
.courses-single-left .course-terms ul li .course-category span {
  color: #8a8a8a;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.courses-single-left .course-terms ul li .teacher-name .name h6,
.courses-single-left .course-terms ul li .course-category h6 {
  font-size: 16px;
  color: #24486e;
  font-weight: 700;
}

.courses-single-left .course-terms ul li .review {
}

.courses-single-left .course-terms ul li .review ul li {
  display: inline-block;
}

.courses-single-left .course-terms ul li .review ul li a {
  font-size: 14px;
  color: #ffc600;
}

.courses-single-left .course-terms ul li .review ul li.rating {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-left: 13px;
}

.courses-single-image {
}

.courses-single-image img {
  width: 100%;
}

.courses-tab {
  border: 1px solid #edf0f2;
  border-radius: 5px;
}

.courses-tab .nav {
}

.courses-tab .nav .nav-item {
}

.courses-tab .nav .nav-item a {
  font-size: 16px;
  color: #24486e;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding: 20px 0;
  display: block;
  background-color: #edf0f2;
}

.courses-tab .nav .nav-item a.active {
  background-color: #fff;
}

.overview-description {
  padding: 0px 30px 25px;
}

.overview-description .single-description {
}

.overview-description .single-description h6 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
  padding-bottom: 10px;
}

.curriculum-cont {
  padding: 25px 30px;
}

.curriculum-cont .title h6 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
  padding-bottom: 30px;
}

.curriculum-cont .accordion {
  border: 0;
}

.curriculum-cont .accordion .card {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #cecece !important;
}

.curriculum-cont .accordion .card:last-child {
  border-bottom: 0 !important;
}

.curriculum-cont .accordion .card .card-header {
  padding: 0;
  border-bottom: 0;
}

.curriculum-cont .accordion .card .card-header a {
  overflow: hidden;
  display: block;
  padding: 15px 15px;
  background-color: #edf0f2;
}

.curriculum-cont .accordion .card .card-header a.collapsed {
  background-color: #fff;
  border: none;
}

.curriculum-cont .accordion .card .card-header a::before,
.curriculum-cont .accordion .card .card-header a.collapsed::before {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 18px;
  color: #8a8a8a;
  position: absolute;
  top: 15px;
  right: 15px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.curriculum-cont .accordion .card .card-header a.collapsed:before {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.curriculum-cont .accordion .card .card-header a ul li {
  display: inline-block;
}

.curriculum-cont .accordion .card .card-header a ul li:last-child {
  float: right;
}

.curriculum-cont .accordion .card .card-header a ul li > i {
  color: #ffc600;
  font-size: 15px;
  margin-right: 5px;
}

.curriculum-cont .accordion .card .card-header a ul li > .lecture {
  font-size: 15px;
  color: #8a8a8a;
}

.curriculum-cont .accordion .card .card-header a ul li > .head {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-left: 15px;
  color: #000;
}

.curriculum-cont .accordion .card .card-header a ul li > .time {
  font-size: 15px;
  color: #8a8a8a;
  text-align: right;
  padding-right: 30px;
}

.curriculum-cont .accordion .card .card-header a ul li > .time i {
  margin-right: 5px;
}

.curriculum-cont .accordion .card .card-header a ul li > .time span {
}

.curriculum-cont .accordion .card .card-body {
  background-color: #edf0f2;
  padding: 0 25px 20px;
}

.instructor-cont {
  padding: 30px 30px 25px;
}

.instructor-cont .instructor-author {
  overflow: hidden;
}

.instructor-cont .instructor-author .author-thum {
  float: left;
  margin-right: 30px;
}

.instructor-cont .instructor-author .author-name {
  float: left;
}

.instructor-cont .instructor-author .author-name a {
  display: block;
}

.instructor-cont .instructor-author .author-name a h5 {
  font-size: 18px;
  color: 30px;
  font-weight: 600;
}

.instructor-cont .instructor-author .author-name span {
  font-size: 15px;
  color: #8a8a8a;
}

.instructor-cont .instructor-author .author-name .social {
  padding-top: 25px;
}

.instructor-cont .instructor-author .author-name .social li {
  display: inline-block;
  margin-right: 8px;
}

.instructor-cont .instructor-author .author-name .social li a {
  font-size: 16px;
  width: 35px;
  height: 35px;
  line-height: 33px;
  border: 1px solid #24486e;
  background-color: #24486e;
  color: #fff;
  text-align: center;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.instructor-cont .instructor-author .author-name .social li a:hover {
  background-color: #fff;
  color: #24486e;
}

.reviews-cont {
  padding: 25px 30px 30px;
}

.reviews-cont .title h6 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
}

.reviews-cont ul li .single-reviews {
  padding-top: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid #d2d2d2;
}

.reviews-cont ul li:last-child .single-reviews {
  border-bottom: 0;
}

.reviews-cont ul li .single-reviews .reviews-author {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviews-cont ul li .single-reviews .reviews-author .author-thum img {
  border-radius: 5px;
}

.reviews-cont ul li .single-reviews .reviews-author .author-name {
  padding-left: 20px;
}

.reviews-cont ul li .single-reviews .reviews-author .author-name h6 {
  font-size: 16px;
  font-weight: 600;
}

.reviews-cont ul li .single-reviews .reviews-author .author-name span {
  font-size: 15px;
  color: #8a8a8a;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.reviews-cont ul li .single-reviews .reviews-description {
}

.reviews-cont ul li .single-reviews .reviews-description p {
  padding-bottom: 17px;
}

.reviews-cont ul li .single-reviews .reviews-description .rating {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.reviews-cont ul li .single-reviews .reviews-description .rating ul li {
  display: inline-block;
  font-size: 15px;
  color: #ffc600;
}

.reviews-cont ul li .single-reviews .reviews-description .rating span {
  font-size: 15px;
  color: #000;
  margin-left: 10px;
}

.reviews-cont .reviews-form .form-single {
  padding-top: 25px;
}

.reviews-cont .reviews-form .form-single input,
.reviews-cont .reviews-form .form-single textarea {
  width: 100%;
  height: 45px;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 0 20px;
}

.reviews-cont .reviews-form .form-single textarea {
  height: 160px;
  padding-top: 20px;
  resize: none;
}

.reviews-cont .reviews-form .form-single .rate-wrapper {
  overflow: hidden;
}

.reviews-cont .reviews-form .form-single .rate-label {
  float: left;
  color: #000;
  margin-right: 10px;
  margin-left: 0;
}

.reviews-cont .reviews-form .form-single .rate {
  float: left;
  color: #cecece;
  cursor: pointer;
}

.reviews-cont .reviews-form .form-single .rate-item {
  float: left;
  cursor: pointer;
  margin: 0px 3px 0px 3px;
}

.reviews-cont .reviews-form .form-single .rate:hover,
.reviews-cont .reviews-form .form-single .rate.selected {
  color: #ffc600;
}

.reviews-cont .reviews-form .form-single .rate .rate-item:hover ~ .rate-item,
.reviews-cont .reviews-form .form-single .rate .rate-item.active ~ .rate-item {
  color: #cecece;
}

.related-courses .title h3 {
  font-size: 30px;
  color: #586470;
}

.course-features {
  background-color: #fff;
  padding: 30px 35px;
  border-radius: 5px;
}

.course-features h4,
.You-makelike h4 {
  font-size: 24px;
  color: #586470;
  padding-bottom: 15px;
}

.course-features ul {
}

.course-features ul li {
  font-size: 15px;
  color: #8a8a8a;
  overflow: hidden;
  line-height: 45px;
  border-bottom: 1px solid #d2d2d2;
}

.course-features ul li:last-child {
  border-bottom: 0;
}

.course-features ul li i {
  color: #ffc600;
  margin-right: 8px;
}

.course-features ul li span {
  float: right;
}

.course-features .price-button {
  overflow: hidden;
}

.course-features .price-button span {
  font-size: 18px;
  color: #24486e;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 12px;
}

.course-features .price-button span b {
  color: #ffc600;
}

.course-features .price-button .main-btn {
  float: right;
}

.You-makelike {
  background-color: #fff;
  padding: 25px 30px 30px;
  border-radius: 5px;
}

.You-makelike .single-makelike {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.You-makelike .single-makelike .image {
  position: relative;
}

.You-makelike .single-makelike .image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(7, 41, 77, 0.8);
}

.You-makelike .single-makelike .image img {
  width: 100%;
}

.You-makelike .single-makelike .cont {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 30px;
}

.You-makelike .single-makelike .cont h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.You-makelike .single-makelike .cont h4:hover {
  color: #ffc600;
}

.You-makelike .single-makelike .cont ul li {
  display: inline-block;
  margin-right: 15px;
  color: #ffc600;
}

.You-makelike .single-makelike .cont ul li a {
  font-size: 14px;
  color: #fff;
}

.You-makelike .single-makelike .cont ul li a i {
  margin-right: 3px;
}

/*=====================================================
    24. EVENT PAGE css
======================================================*/

.single-event-list {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-align-items: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.single-event-list .event-thum {
  width: 38%;
}

.single-event-list .event-thum img {
  width: 100%;
  border-radius: 5px;
}

.single-event-list .event-cont {
  width: 62%;
  padding-left: 30px;
}

.single-event-list .event-cont span {
  font-size: 14px;
  color: #8a8a8a;
  margin-right: 20px;
}

.single-event-list .event-cont span i {
  color: #ffc600;
  margin-right: 3px;
}

.single-event-list .event-cont a {
  display: block;
}

.single-event-list .event-cont a h4 {
  padding-top: 5px;
  padding-bottom: 10px;
  color: #000;
  font-size: 20px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-event-list .event-cont a:hover h4 {
  color: #ffc600;
}

/*=====================================================
    25. EVENT SINGEL PAGE css
======================================================*/

.events-area {
  background-color: #fff;
  padding: 45px 50px;
}

.events-left {
}

.events-left h3 {
  padding-top: 5px;
  padding-bottom: 10px;
  color: #000;
  font-size: 30px;
}

.events-left span {
  font-size: 14px;
  color: #8a8a8a;
  margin-right: 20px;
}

.events-left span i {
  color: #ffc600;
  margin-right: 3px;
}

.events-left img {
  margin-top: 35px;
  border-radius: 5px;
}

.events-left p {
  padding-top: 31px;
}

.events-right {
}

.events-coundwon {
  padding: 25px 30px 30px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 110px;
}

.events-coundwon .count-down-time {
  position: relative;
  z-index: 5;
}

.events-coundwon .count-down-time .single-count {
}

.events-coundwon .count-down-time .single-count .number {
  font-size: 24px;
  color: #ffc600;
}

.events-coundwon .count-down-time .single-count .title {
  font-size: 13px;
}

.events-coundwon .events-coundwon-btn {
}

.events-coundwon .events-coundwon-btn .main-btn {
  width: 100%;
}

.events-address {
  border: 1px solid #bcbcbc;
  padding: 0 30px 30px;
  border-radius: 5px;
}

.events-address ul li {
  padding-top: 20px;
}

.events-address ul li .single-address {
  position: relative;
}

.events-address ul li .single-address .icon {
  position: absolute;
  top: 0;
  left: 0;
}

.events-address ul li .single-address .icon i {
  font-size: 15px;
  color: #ffc600;
}

.events-address ul li .single-address .cont {
  padding-left: 23px;
}

.events-address ul li .single-address .cont h6 {
  font-size: 15px;
  color: #586470;
}

.events-address ul li .single-address .cont span {
  font-size: 14px;
  color: #505050;
}

#contact-map {
  width: 100%;
  height: 150px;
  border-radius: 5px;
}

/*=====================================================
    26. TEACHERS SInGEL PAGE css
======================================================*/

.teachers-left {
  padding: 40px 50px 35px;
  background-color: #fff;
  border-radius: 5px;
}

.teachers-left .hero img {
  width: 100%;
  border-radius: 5px;
}

.teachers-left .name {
  padding-top: 25px;
}

.teachers-left .name h6 {
  font-size: 18px;
  color: #000;
}

.teachers-left .name span {
  font-size: 15px;
  color: #8a8a8a;
}

.teachers-left .social {
  padding-top: 15px;
}

.teachers-left .social ul li {
  display: inline-block;
}

.teachers-left .social ul li a {
  font-size: 30px;
  margin-right: 7px;
}

.teachers-left .social ul li:nth-of-type(1) a {
  color: #212798;
}

.teachers-left .social ul li:nth-of-type(2) a {
  color: #3c9bff;
}

.teachers-left .social ul li:nth-of-type(3) a {
  color: #fe1f59;
}

.teachers-left .social ul li:nth-of-type(4) a {
  color: #474b8c;
}

.teachers-left .description {
  padding-top: 20px;
}

.teachers-right {
  border-radius: 5px;
  overflow: hidden;
}

.teachers-right .nav {
}

.teachers-right .nav .nav-item {
}

.teachers-right .nav .nav-item a {
  padding: 20px 0;
  background-color: #315377;
  color: #fff;
  font-weight: 15;
  color: #fff;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.teachers-right .nav .nav-item a.active,
.teachers-right .nav .nav-item a:hover {
  background-color: #fff;
  color: #315377;
}

.teachers-right .tab-content {
  padding: 0px 50px 35px;
  background-color: #fff;
}

.teachers-right .tab-content .dashboard-cont {
}

.teachers-right .tab-content .dashboard-cont .single-dashboard h5 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  padding-bottom: 15px;
}

.teachers-right .tab-content .reviews-cont {
  padding: 45px 0 15px;
}

.teachers-right .tab-content {
}

/*=====================================================
    27. BLOG PAGE css
======================================================*/

.single-blog {
  overflow: hidden;
  border-radius: 5px;
}

.single-blog .blog-thum img {
  width: 100%;
}

.single-blog .blog-cont {
  padding: 40px 50px;
  background-color: #fff;
}

.single-blog .blog-cont a {
  display: block;
}

.single-blog .blog-cont h3 {
  font-size: 36px;
  color: #000;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-blog .blog-cont a:hover h3 {
  color: #ffc600;
}

.single-blog .blog-cont ul {
  padding: 20px 0;
}

.single-blog .blog-cont ul li {
  display: inline-block;
  margin-right: 30px;
}

.single-blog .blog-cont ul li:last-child {
  margin-right: 0;
}

.single-blog .blog-cont ul li a {
  font-size: 15px;
  color: #8a8a8a;
}

.single-blog .blog-cont ul li a i {
  color: #ffc600;
  margin-right: 5px;
}

.single-blog .blog-cont p {
}

.sidebar{
}

.sidebar .sidebar-search {
  padding: 35px;
  background-color: #fff;
  border-radius: 5px;
}

.sidebar .sidebar-search form {
  position: relative;
}

.sidebar .sidebar-search form input {
  width: 100%;
  height: 45px;
  padding: 0 25px;
  background-color: #f6f6f6;
  color: #8a8a8a;
  border-radius: 5px;
  font-size: 15px;
  border: 0;
}

.sidebar .sidebar-search form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  font-size: 16px;
  color: #8a8a8a;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.sidebar .categories {
  background-color: #fff;
  padding: 30px 35px 25px;
  border-radius: 5px;
}

.sidebar .categories h4,
.sidebar .sidebar-post h4 {
  font-size: 24px;
  color: #586470;
}

.sidebar .categories ul {
  padding-top: 15px;
}

.sidebar .categories ul li {
  line-height: 40px;
}

.sidebar .categories ul li a {
  color: #8a8a8a;
  font-weight: 15;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.sidebar .categories ul li a:hover {
  color: #ffc600;
}

.sidebar .sidebar-post {
  padding: 30px 35px 35px;
  background-color: #fff;
  border-radius: 5px;
}

.sidebar .sidebar-post ul {
  padding-top: 10px;
}

.sidebar .sidebar-post ul li a {
  display: block;
  margin-top: 30px;
}

.sidebar .sidebar-post ul li a .single-post {
  overflow: hidden;
}

.sidebar .sidebar-post ul li a .single-post .thum {
  float: left;
  padding-right: 20px;
}

.sidebar .sidebar-post ul li a .single-post .thum img {
  border-radius: 5px;
}

.sidebar .sidebar-post ul li a .single-post .cont {
}

.sidebar .sidebar-post ul li a .single-post .cont h6 {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  padding-bottom: 10px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.sidebar .sidebar-post ul li a .single-post .cont h6:hover {
  color: #ffc600;
}

.sidebar .sidebar-post ul li a .single-post .cont span {
  font-size: 14px;
  color: #8a8a8a;
}

/*=====================================================
    28. BLOG SINGEL PAGE css
======================================================*/

.blog-details > .thum {
  position: relative;
}

.blog-details .thum {
  overflow: hidden;
}

.blog-details .thum img {
  width: 100%;
  height: 500px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;

}

.blog-details:hover .thum img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-details .cont {
  padding: 40px 50px;
  background-color: #fff;
}

.blog-details .cont h3 {
  font-size: 36px;
  color: #000;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.blog-details .cont ul {
  padding: 20px 0;
}

.blog-details .cont ul li {
  display: inline-block;
  margin-right: 30px;
}

.blog-details .cont ul li a {
  font-size: 15px;
  color: #8a8a8a;
}

.blog-details .cont ul li a i {
  color: #ffc600;
  margin-right: 5px;
}

.blog-details .cont .share {
  padding-top: 22px;
  border-bottom: 1px solid #cecece;
  padding-bottom: 30px;
}

.blog-details .cont .share li.title {
  font-size: 18px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.blog-details .cont .share > li {
  display: inline-block;
  margin-right: 10px;
}

.blog-details .cont .share li a {
}

.blog-details .cont .share li a i {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  font-size: 16px;
  background-color: #ffc600;
  text-align: center;
  color: #fff;
  margin-right: 0;
}

.blog-details .cont .share li:nth-of-type(2) a i {
  background-color: #3c5a99;
}

.blog-details .cont .share li:nth-of-type(3) a i {
  background-color: #1da1f2;
}

.blog-details .cont .share li:nth-of-type(4) a i {
  background-color: #d34836;
}

.blog-details .cont .share li:nth-of-type(5) a i {
  background-color: #e4405f;
}

.blog-details .cont .share li:nth-of-type(6) a i {
  background-color: #0077b5;
}

.blog-details .cont .blog-comment .title h3 {
  font-size: 24px;
  color: #000;
}

.blog-details .cont .blog-comment ul {
  padding: 0;
}

.blog-details .cont .blog-comment ul li {
  margin-top: 30px;
  margin-right: 0;
}

.blog-details .cont .blog-comment ul li .replay {
  padding-left: 75px;
  padding-top: 0;
  padding-bottom: 0;
}

.blog-comment .comment {
  padding-bottom: 25px;
  position: relative;
  border-bottom: 1px solid #d2d2d2;
}

.blog-comment .comment .comment-author {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-comment .comment .comment-author .author-thum img {
  border-radius: 5px;
}

.blog-comment .comment .comment-author .comment-name {
  padding-left: 20px;
}

.blog-comment .comment .comment-author .comment-name h6 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.blog-comment .comment .comment-author .comment-name span {
  font-size: 15px;
  color: #8a8a8a;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.blog-comment .comment .comment-replay {
  position: absolute;
  top: 15px;
  right: 0;
}

.comment-form .form-single {
  margin-top: 20px;
}

.comment-form .form-single input,
.comment-form .form-single textarea {
  height: 60px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  font-size: 15px;
}

.comment-form .form-single textarea {
  height: 120px;
  resize: none;
  padding-top: 20px;
}

/*=====================================================
    29. SHOP PAGE css
======================================================*/

.shop-top-search {
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
}

.shop-top-search .shop-bar {
  display: inline-block;
}

.shop-top-search .nav .nav-item {
  margin-right: 15px;
  font-size: 15px;
  color: #8a8a8a;
}

.shop-top-search .nav .nav-item a {
  font-size: 16px;
  color: #8a8a8a;
}

.shop-top-search .nav .nav-item a.active {
  color: #ffc600;
}

.shop-top-search .shop-select {
  display: inline-block;
  float: right;
  margin-top: -3px;
}

.shop-top-search .shop-select .nice-select {
  background-color: #f6f6f6;
  border-radius: 5px;
  border: 0;
  font-size: 14px;
  height: 30px;
  width: 135px;
  line-height: 30px;
  color: #8a8a8a;
}

.shop-top-search .shop-select .nice-select.open .list {
  width: 100%;
  margin-top: 0;
  background-color: #f6f6f6;
  border: 0;
}

#shop-list .single-publication .cont {
  display: block;
  padding-top: 0;
}

#shop-list .single-publication .cont .name {
  width: 100%;
}

#shop-list .single-publication .cont .name a h6 {
  font-size: 18px;
}

#shop-list .single-publication .cont .name span {
  font-size: 16px;
  color: #8a8a8a;
  padding-top: 15px;
}

#shop-list .single-publication .cont .button {
  width: 100%;
  padding-top: 10px;
}

#shop-list .single-publication .cont .button a {
  padding: 0 20px;
  font-size: 12px;
  line-height: 35px;
}

/*=====================================================
    30. SHOP SINGEL PAGE css
======================================================*/

.shop-details {
  background-color: #fff;
  padding: 20px 50px 50px;
  border-radius: 5px;
}

.shop-details .shop-left {
  padding-right: 50px;
}

.shop-details .shop-left .shop-image a {
  display: block;
}

.shop-details .shop-left .shop-image img {
  width: 100%;
  border-radius: 4px;
}

.shop-details .shop-left .nav .nav-item {
  margin-right: 25px;
}

.shop-details .shop-left .nav .nav-item:last-child {
  margin-right: 0;
}

.shop-details .shop-left .nav .nav-item a {
  display: block;
}

.shop-details .shop-left .nav .nav-item a .shop-thum img {
  width: 100%;
  border-radius: 3px;
}

.shop-right {
}

.shop-right h6 {
  font-size: 18px;
  padding-bottom: 5px;
}

.shop-right span {
  font-size: 15px;
  color: #8a8a8a;
}

.shop-right p {
  padding-bottom: 15px;
}

.nice-number {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.nice-number input {
  width: 50px !important;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #aaa;
  padding-right: 20px;
}

.nice-number button {
  position: absolute;
  width: 25px;
  padding: 0;
  right: 2px;
  top: 2px;
  border: 0;
  border-top-right-radius: 5px;
  background: none;
  cursor: pointer;
}

.nice-number button:last-child {
  top: auto;
  bottom: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
}

.nice-number button i {
  font-size: 15px;
  color: #000;
}

.add-btn {
  display: inline-block;
}

.add-btn .main-btn {
  line-height: 46px;
}

.shop-reviews .nav .nav-item {
}

.shop-reviews .nav .nav-item:first-child a {
  border-top-left-radius: 5px;
}

.shop-reviews .nav .nav-item:last-child a {
  border-top-right-radius: 5px;
}

.shop-reviews .nav .nav-item a {
  width: 165px;
  line-height: 55px;
  background-color: #315377;
  font-size: 15px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: #fff;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.shop-reviews .nav .nav-item a.active {
  background-color: #fafafa;
  color: #315377;
}

.shop-reviews .tab-content {
  background-color: #fafafa;
  padding: 0px 45px 40px;
  border-radius: 5px;
  border-top-left-radius: 0;
}

.shop-reviews .tab-content .reviews-cont {
  padding: 0;
}

.related-item {
}

.related-item .title h3 {
  font-size: 30px;
  color: #586470;
}

/*=====================================================
    31. CONTACT PAGE css
======================================================*/

.contact-from {
  background-color: #fff;
  padding: 50px;
  border-radius: 5px;
}

.contact-from .main-form {
}

.contact-from .main-form .single-form {
  margin-top: 20px;
}

.contact-from .main-form .single-form input,
.contact-from .main-form .single-form textarea {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  color: #8a8a8a;
  font-size: 15px;
}

.contact-from .main-form .single-form textarea {
  padding-top: 10px;
  height: 100px;
  resize: none;
}

.form-group {
  margin: 0;
}

.list-unstyled li {
  font-size: 13px;
  margin-left: 2px;
  margin-top: 5px;
  color: #f00;
}

p.form-message.success,
p.form-message.error {
  font-size: 16px;
  color: #353535;
  background: #ddd;
  padding: 10px 15px;
  margin-left: 15px;
  margin-top: 15px;
}

.contact-address {
  background-color: #fff;
  padding: 20px 50px 50px;
  border-radius: 5px;
  overflow-y: scroll;
  height: 596px;
}

.contact-address ul li {
  padding-top: 30px;
}

.contact-address ul li .single-address {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-address ul li .single-address .icon i {
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  border: 1px solid #586470;
  color: #586470;
  font-size: 24px;
  text-align: center;
}

.contact-address ul li .single-address .cont {
  padding-left: 20px;
}

.contact-address ul li .single-address .cont p {
}

.map {
}

.map #contact-map {
  width: 100%;
  height: 225px;
}

.map.map-big #contact-map {
  width: 100%;
  height: 415px;
}

.contact-address .contact-heading {
  padding-top: 35px;
}

.contact-address .contact-heading h5 {
  padding-bottom: 15px;
}

/*=====================================================
    32. TEACHER 2 PAGE css
======================================================*/

.single-teacher-2 {
  background-color: #fff;
  border-radius: 5px;
}

.teacher-image {
  overflow: hidden;
  position: relative;
}

.teacher-image img {
  width: 100%;
}

.single-teacher-2 .teacher-image .teacher-social {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 70px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 5;
  -webkit-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
}

.single-teacher-2 .teacher-image:hover .teacher-social {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.single-teacher-2 .teacher-image .teacher-social ul li {
  display: inline-block;
  margin: 0 5px;
}

.single-teacher-2 .teacher-image .teacher-social ul li a {
  font-size: 16px;
  color: #586470;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.single-teacher-2 .teacher-image .teacher-social ul li a:hover {
  color: #fff;
}

.single-teacher-2 .teacher-content {
  padding: 15px 0;
}

.single-teacher-2 .teacher-content .teacher-title a {
  color: #000;
  font-size: 15px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-teacher-2 .teacher-content .teacher-title a:hover {
  color: #ffc600;
}

.single-teacher-2 .teacher-content span {
  font-size: 14px;
  color: #8a8a8a;
}

/*=====================================================
    33.ADMISSION  css
======================================================*/

.admission-row {
  position: relative;
}

.admission-info {
  background-color: #586470;
  padding: 70px 40px;
}

.admission-info .admission-title {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 30px;
}

.admission-info p {
  color: #fff;
  padding-bottom: 20px;
}

.admission-info .help-line {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 5px;
}

.admission-info span {
  font-size: 30px;
  font-weight: 600;
  color: #ffc600;
}

.admission-card {
  background-color: #586470;
}

.admission-card .card-image img {
  width: 100%;
}

.admission-card .card-content {
  padding: 40px;
}

.admission-card .card-content .card-titles {
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 20px;
}

.admission-card .card-content p {
  color: #fff;
}

/*=====================================================
    34.COURSE  css
======================================================*/

.course-area {
  position: relative;
}

.course-area::before {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/course/course-shape.png);
  background-position: center center;
  background-size: contain;
}

.single-course-three {
}

.single-course-three .course-image {
}

.single-course-three .course-image img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.single-course-three .course-content {
  padding: 15px 20px;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}

.single-course-three .course-content .rating {
}

.single-course-three .course-content .rating li {
  display: inline-block;
  font-size: 14px;
  color: #ffc600;
}

.single-course-three .course-content .rating span {
  font-size: 14px;
  color: #8a8a8a;
  margin-left: 10px;
}

.single-course-three .course-content .course-title a {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.single-course-three .course-content .course-title a:hover {
  color: #ffc600;
}

.course-meta {
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.course-meta .price {
  float: left;
}

.course-meta .price span {
  font-size: 16px;
  color: #586470;
}

.course-meta .admin {
  float: right;
}

.course-meta .admin ul li {
  display: inline-block;
  margin-right: 15px;
}

.course-meta .admin ul li:last-child {
  margin-right: 0;
}

.course-meta .admin ul li a {
  font-size: 16px;
  color: #8a8a8a;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.course-meta .admin ul li a:hover {
  color: #ffc600;
}

.course-right {
}

.course-signup {
  padding: 50px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.course-signup .content {
  position: relative;
  z-index: 9;
}

.course-signup .content .signup-title {
  font-size: 30px;
  color: #ffc600;
  font-weight: 700;
  padding-bottom: 10px;
}

.course-signup .content p {
  font-size: 24px;
  color: #fff;
  padding-bottom: 40px;
  font-weight: 600;
}

.enroll {
  padding: 50px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.enroll .content .enroll-title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  padding-bottom: 10px;
}

.enroll .content p {
  font-size: 24px;
  color: #505050;
  font-weight: 600;
  padding-bottom: 40px;
}

.course-Buy {
  padding: 50px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.course-Buy .content {
  position: relative;
  z-index: 9;
}

.course-Buy .Buy-title {
  font-size: 24px;
  color: #fff;
  padding-bottom: 20px;
}

/*=====================================================
    32. ACCORDION
======================================================*/

.faq-accordion-cont .title h6 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #000;
  padding-bottom: 30px;
}

.faq-accordion-cont .accordion {
  border: 0;
}

.faq-accordion-cont .accordion .card {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #cecece !important;
}

.faq-accordion-cont .accordion .card:last-child {
  border-bottom: 0 !important;
}

.faq-accordion-cont .accordion .card .card-header {
  padding: 0;
  border-bottom: 0;
}

.faq-accordion-cont .accordion .card .card-header a {
  overflow: hidden;
  display: block;
  padding: 17px 25px;
  background-color: #586470;
}

.faq-accordion-cont .accordion .card .card-header a.collapsed {
  background-color: #fff;
  border: none;
}

.faq-accordion-cont .accordion .card .card-header a::before,
.faq-accordion-cont .accordion .card .card-header a.collapsed::before {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 22px;
  color: #fff;
  position: absolute;
  top: 15px;
  right: 25px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.faq-accordion-cont .accordion .card .card-header a.collapsed:before {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  color: #8a8a8a;
}

.faq-accordion-cont .accordion .card .card-header a ul li {
  display: inline-block;
}

.faq-accordion-cont .accordion .card .card-header a ul li:last-child {
  float: right;
}

.faq-accordion-cont .accordion .card .card-header a ul li > i {
  color: #ffc600;
  font-size: 16px;
  margin-right: 5px;
}

.faq-accordion-cont .accordion .card .card-header a ul li > .lecture {
  font-size: 15px;
  color: #8a8a8a;
}

.faq-accordion-cont .accordion .card .card-header a ul li > .head {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-left: 15px;
  color: #fff;
}

.faq-accordion-cont .accordion .card .card-header a.collapsed ul li > .head {
  color: #586470;
}

.faq-accordion-cont .accordion .card .card-header a ul li > .time {
  font-size: 15px;
  color: #8a8a8a;
  text-align: right;
  padding-right: 30px;
}

.faq-accordion-cont .accordion .card .card-header a ul li > .time i {
  margin-right: 5px;
}

.faq-accordion-cont .accordion .card .card-body {
  padding: 30px;
}

/*=====================================================
    33. PRIVACY POLICY
======================================================*/

.privacy-desc p {
  margin-bottom: 25px;
}

.privacy-list ul {
  list-style: disc;
  padding-left: 20px;
}

.privacy-list ul li {
  padding: 6px 0;
}

.privacy-list ul li a {
  color: #586470;
  font-weight: 700;
  transition: 0.3s;
}

.privacy-list ul li a:hover {
  color: #ffc600;
  transition: 0.3s;
}

/*=====================================================
    34. LOGIN/REGISTER
======================================================*/

.signup-content {
  background: #fff;
  border-radius: 10px;
  padding: 50px 55px;
  margin: 0 50px;
}

.form-group {
  overflow: hidden;
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #586470;
}

.main-btn.register-submit {
  width: 170px;
}

.label-agree-term {
  font-size: 15px;
  color: #555;
}

.term-service {
  color: #586470;
  transition: 0.3s;
}

a.term-service:hover {
  color: #ffc600;
  transition: 0.3s;
}

.loginhere {
  color: #555;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}

.loginhere-link {
  font-weight: 700;
  color: #586470;
  transition: 0.3s;
}

a.loginhere-link:hover {
  color: #ffc600;
  transition: 0.3s;
}

.field-icon {
  float: right;
  margin-right: 17px;
  margin-top: -32px;
  position: relative;
  z-index: 2;
  color: #555;
}

@media screen and (max-width: 768px) {
  .signup-content {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .signup-content {
    margin: 0;
  }
}

.site-footer {
  padding-top: 30px;
  background-color: #586470;
}

#footer-flag {
  position: absolute;
  left: 0;
}

#footer-bar-green {
  width: 33.3%;
  border: 7px solid #008559;
}

#footer-bar-yellow {
  width: 33.3%;
  border: 7px solid #ffd400;
}

#footer-bar-red {
  width: 33.3%;
  border: 7px solid #ff1f00;
}

#newsletter-part {
  background-color: rgb(0, 68, 148);
}

.lien-utlies-section {
  border-top: 2px solid #ffffff;
}

.site-footer .flag-look-like {
  display: flex;
  height: 10px;
}

.site-footer .flag-look-like .green-block {
  background-color: #108757;
}

.site-footer .flag-look-like .yellow-block {
  background-color: #fbd142;
}

.site-footer .flag-look-like .red-block {
  background-color: #e92d38;
}

.site-footer .footer-logo {
  height: 70px;
}
.footer-logo2{
  height: 90px;
}
.white-img {
  filter: brightness(0) invert(1);
}
.site-secondary-text-color {
  color: #ffd400 !important;
}

.site-secondary-bgcolor {
  background-color: #ffd400 !important;
}

.directeutImage {
  height: 90px;
}

/*************** DOCUMENT PAGE*************/

.newsletter-input {
  padding-top: 30px;
  padding-bottom: 30px;
}

.relative-doc {
  position: relative;
  display: block;
}

.doc-thumb {
  min-width: 10%;
}

.h-full {
  height: 100%;
}

.trans {
  opacity: 0.45;
}

.doc-thumb .doc-semi {
  height: 50%;
}

.doc-thumb img {
  opacity: 0.1;
}

.filter-white {
  filter: contrast(0%) brightness(200%);
}

.doc-thumb .cat,
.doc-thumb .num {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
}

.doc-infos {
  border-right: 0.14vw solid #d8d8d8;
}

.doc-absolute.bottom {
  bottom: 0px;
  top: unset;
}

.doc-thumb .flag {
  height: calc(7px * 0.5);
  min-height: unset;
  min-width: unset;
}

.doc-absolute {
  position: absolute;
}

.doc-block-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.doc-info {
  border-right: 0.14vw solid #d8d8d8;
}

.doc-middle {
  justify-content: center;
  align-items: center;
}

.flag > * {
  height: 100%;
  width: 33.3%;
  z-index: 2;
}

.semibold,
.medium {
  font-weight: 600;
}

.doc-thumb i {
  font-style: normal;
}

.doc-thumb-text {
  padding-bottom: 27px !important;
  padding-top: 25px !important;
  text-align: center;
}

.right-20-in.adapt {
  padding-right: 20px;
}

.left-20-in.adapt {
  padding-left: 20px;
}

.doc-thumb figure {
  margin-bottom: none !important;
}

.action-btn {
  border-bottom: 8px solid rgb(16, 135, 87);
  position: relative;
  top: 91px;
}

.action-btn button {
  letter-spacing: 3px;
  color: #586470;
}

.action-btn button:hover {
  color: #586470;
  text-decoration: none;
}

.action-btn-block {
  width: 20.5%;
}

.action-btn .download {
  float: right;
}

.mblock {
  font-weight: 600;
  font-size: 15px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #586470 !important;
}

.about-img {
  width: 200px;
  margin-left: 10px;
  margin-top: 30px;
  float: right;
}
.round-sociale-media {
  width: 35px;
  height: 35px;
  color: white;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  margin-right: 14px;
  background-color: #586470;
}

.card_service-g::after {
  content: "";
  right: 15px;
  bottom: 0;
  top: 59px;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 0 12.78px 16.77px;
  border-color: transparent transparent #008559 transparent;
}

.card_service-g :hover {
  box-shadow: 0px 3px 19px rgba(0, 133, 89, 0.4);
  background-color: #008559;
  color: #fff;
}

.card_service-y::after {
  content: "";
  right: 15px;
  bottom: 0;
  top: 59px;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 0 12.78px 16.77px;
  border-color: transparent transparent #ffd400 transparent;
}

.card_service-y :hover {
  box-shadow: 0px 3px 19px rgba(255, 215, 0, 0.4);
  background-color: #ffd400;
  color: #fff;
}
.liens {
  font-size: 11px !important;
}

.lien-icon {
  background-color: #595d7c;
  padding: 5px 10px 5px 10px;
  border-radius: 50%;
}

.list-group-item.active {
  background-color: #586470;
  border-color: #586470;
}
.agric-icon {
  color: #fff;
}
.category-img {
  width: 77px;
}
.director-img {
  width: 331px;
}
#statistic-option li.active {
  background-color: #4aa340;
  border-radius: 15px;
}
#statistic-option li.active a {
  color: #fff !important;
}

.phototheque-img {
  height: 600px;
}
#othermenus {
  /* background-color: rgba(255, 255, 255, 0.9); */
  margin-top: -130px;
  margin-bottom: 20px;
  position: relative;
}
.other-menu-sub li a {
  font-size: 14px;
}
.other-menu-sub-green {
  background-color: rgb(74, 163, 64) !important;
}
.other-menu-sub-list-group li a {
  color: #fff;
}
.financedBy {
  color: #999999;
}

.extramenu-contact {
  position: fixed;
  z-index: 2;
  right: 0%;
  top: 30.5%;
  background-color: rgb(74, 163, 64);
  padding: 14px;
  color: rgb(255, 255, 255) !important;
  font-size: small;
}
.extramenu-drawer {
  position: fixed;
  z-index: 2;
  right: 0%;
  top: 36.6%;
  background-color: rgb(74, 163, 64);
  padding: 15px;
  color: rgb(255, 255, 255) !important;
  border-top: 1px solid #fff;
  font-size: 12px !important;
}
.extramenu-drawer-nav-show {
  width: 450px !important;
  padding-left: 20px;
  padding-right: 20px;
}
.extramenu-drawer-nav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 100; /* Stay on top */
  top: 0;
  right: 0;
  background-color: #f2f2f2; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 90px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.extramenu-drawer-nav .drawer-closebtn {
  position: absolute;
  top: 11%;
  right: 90%;
  font-size: 36px;
  margin-left: 50px;
  color: rgb(74, 163, 64);
}
.cursor-point {
  cursor: pointer;
}
.ficheprojet-list {
  line-height: 35px;
  list-style: disc;
  margin-left: 15px;
}
.ficheprojet-table thead th{
    vertical-align: top;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #d3d3d3 /*HTML LightGray*/;
}


.ficheprojet-table .title{
    font-weight: bold;
}
.ficheprojet-table .value{
    font-size: 13px;
}

.big-table-view{
height: 65vh;
width: 100%;
overflow-y: auto;
overflow-x: auto;
}
.synthese-list li a{
  font-weight: bold;
  margin: 7px 0px;
  font-size: 15px;
  
}
.big-modal{
  max-width: unset !important;
}

.extramenu-drawer-icon-holder{
  background-color: #586470;
    padding: 0.3rem 0.9rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    color: #fff;
    margin-top: 2%;
    margin-bottom: 2%;
}

.accordss:hover{
  background-color: rgb(74, 163, 64);
}

.accordss:hover p{
  color: #fff;

}
#facebook a{
  color: #3b5998;
}
#youtube a{
  color: #c4302b;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 50%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 100; /* Stay on top */
  top: 0; /* Stay at the top */
  right: -55%;

  background-color: #ffffff; /* Black*/
  padding: 10px;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.7s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav li,a{
  line-height: 35px;
  color: '#fff';

}

.sidenav a{
  color: '#eeeeee' !important;
}
.sidenav a:hover{
  color: '#17905a' !important;
}
@media screen and (max-width: 1000px) {
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 80%; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    top: 0; /* Stay at the top */
    right: -80%;

    background-color: #00963f; /* Black*/
    padding: 20px;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.7s; /* 0.5 second transition effect to slide in the sidenav */
  }
}

@media screen and (max-width: 600px) {
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 95%; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    top: 0; /* Stay at the top */
    right: -95%;

    background-color: #eaeaea; /* Black*/
    padding: 20px;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.7s; /* 0.5 second transition effect to slide in the sidenav */
  }
}


.sidenav.active {
  
  right: 0px;
}
.sidenav .closebtn {
  position: absolute;
  top: 5px;
  right: 4px;
  font-size: 20px;
  margin-left: 50px;
  color: #555;
}

.sidenav h5 {
  
  font-size: 20px;
  font-weight: 500;
}

.sidenav h6 {
  
  font-size: 18px;
  font-weight: 500;
  color: #ffc600;

}

.full-width{
  width: 100%;
}

.nocursor{
  cursor:none;
}

.nav-link.active {
  font-weight: bold;
}

.site-logo{
  height: 100px !important;
}


.sidebar_service{
  border-radius: 10px;
}

.sidebar_service .list-group-item{
  background-color: #5b5f64;
  border-left:0;
  border-right:0;
}

.sidebar_service .list-group-item:hover{
  background-color: #878d94;

}

.sidebar_service a{
  color: #FFF;
  cursor: pointer;

}

.sidebar_service .list-group-item.active{
  background-color: #00963f;
  border:0
}

.sidebar_service .list-group-item.active a{
 color: #FFF;
}

.page .slick-slide {
  padding: 0 5px;
}

.page .slick-list {
  margin: 0 2em;
}

.page p {
  margin-top: 15px;
  line-height: 38px;
  text-align: justify;
}

.page li {
  line-height: 33px;
  text-align: justify;
}

.page h5, h4{
  color: #576570;
  font-size: 28px;
  font-weight: 600;
}

.page img {
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.page h5::before{
  content: "";
  position: absolute;
  width: 35px;
  height: 3px;
  background-color: #00963f;
  bottom: 0;
  left: 0;
}
.page .download{
  background: #000;
}
.page .objectif {
  background-color: #00963f;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
}

.page .mission {
  background-color: #00963f;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
}

.page .organetop {
  background-color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  margin-bottom: 5px;
}
.page .organebottom {
  background-color: #00963f;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  margin-bottom: 5px;
}

.page .organeaside {
  background-color: #edf0f2;
  height: 450px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  margin-bottom: 5px;
}

.page .strategie {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  background-color: #efefef;

}

.page .valeur {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  background-color: #efefef;

}

.card-header{
  background-color: #949ca3 !important;
}

.organeaside li, .organeaside h5{
  color:#28a745  !important
}


.objectif p, .mission p, .organebottom p, .organebottom li, .objectif li, .objectif h5, .organebottom h5{
  color:#FFF  !important
}


.objectif h5::before, .organebottom h5::before{
  content: "";
  position: absolute;
  width: 35px;
  height: 3px;
  background-color: #ffc107;
  bottom: 0;
  left: 0;
  
}

.page h4::before{
  content: "";
  position: absolute;
  width: 35px;
  height: 3px;
  background-color: #00963f;
  bottom: 0;
  left: 0;
}

.page li {
  padding-left: 10px !important;
  margin-left: 45px !important;
}

.projet{
}
.projet div{
  margin-top: 20px;
  border-right:1px solid#eaeaea;
  border-bottom:1px solid#eaeaea;
  padding: 15px;

}

.projet-action{
  position: absolute;
  right: 5px;
  bottom: 0px;
  font-size: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.projet-body{
  min-height: 150px;
}

.justify p,div{
  text-align: justify;
  line-height: 35px;
}